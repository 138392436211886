import styled from "styled-components/";

export default function Button(props) {
  const { text, color, icon } = props;

  const ButtonLink = styled.a`

    color: ${color === "white" ? "#FFFFFF" : "#248E0E"};
    border: 2px solid ${color === "white" ? "#FFFFFF" : "#58BD92"};
    padding:16px 50px;
    text-decoration:none;
    cursor:pointer;
    transition: all 0.4s;
    ${'' /* margin: 5px; */}
    ${'' /* margin-top: 50px; */}
    border-radius: 5px;
    width: 100px;
    height: 70px;

    ${'' /* style={{ margin: 5, marginTop: 50, borderRadius:20, width: 120, height: 70}}  */}
    ${'' /* borderRadius:20; width: 120; height: 70; */}
    :hover{
      color: ${color === "white" ? "#FFFFFF" : "#248E0E"};
      opacity: .7;
      transition: all 0.4s;
    }

  `;

  return <ButtonLink>{text} <i className={icon}></i></ButtonLink>;
}
