import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import MyButton from "./Button";

export default function IndustriesCovered() {
  // Define the industries you want to display
  const industries = [
    " "
    // "IT",
    // "Social Media",
    // "Sales and Marketing",
    // "Engineering",
    // "Healthcare",
    // "Legal"
  ];

  return (
    // <div className="py-5">
    //   <h2 className="font-primary color-primary">Industries We Cover</h2>
    //   <ul>
    //     {industries.map((industry, index) => (
    //       <li key={index} className="font-secondary color-secondary">{industry}</li>
    //     ))}
    //   </ul>
    // </div>
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg={6} md={6} sm={12}>
          <div className="text-center">
            <h1 className="font-primary color-primary">Industries We Cover</h1>
            <p className="font-secondary color-secondary py-3">
              At RizzOnline, we specialize in connecting talented professionals with opportunities in a wide range of industries. Our expertise extends to the following industries:
            </p>
            <ul className="list-unstyled">
              {industries.map((industry, index) => (
                <li key={index} className="font-secondary color-secondary">
                  {industry}
                </li>
              ))}
            </ul>
            <p className="font-secondary color-secondary py-3">
               IT, Social Media, Sales and Marketing, Engineering, Healthcare, and Legal sectors.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

