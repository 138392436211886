import { Container, Row, Col, Image } from "react-bootstrap";
import MyButton from "./Button";
import img from "../assets/img/image-2.png";

export default function About() {

  const brandStyle = {
    color: 'white',
  };

  return (
    <Container className="py-5" >
      <Row>
        <Col lg={6} md={6} sm={12}>
          <Image src={img} fluid />
        </Col>
        <Col lg={6} md={6} sm={12} className="d-flex align-items-center" style={{marginTop:20}}>
          <div className="text-lg-end text-md-end text-center">
            <h1 className="font-primary color-primary">About Us</h1>
            <p className="font-secondary color-secondary py-3">
            Whether you're looking for a new opportunity or seeking skilled professionals to join your team, we're here to make the process seamless and efficient.
            </p>
            <MyButton text="Contact Us" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
